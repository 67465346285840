// Client Version: 2.10.1
import ReactDOM from 'react-dom/client';
import App from './App'
import './index.css'
import { AuthProvider } from './context/authProvider'

// Declare window type for updateSW
declare global {
  interface Window {
    updateSW?: () => Promise<void>
  }
}

// Register service worker
if ('serviceWorker' in navigator) {
  import('virtual:pwa-register').then(({ registerSW }) => {
    const updateSW = registerSW({
      onNeedRefresh() {
        console.log('UPDATE AVAILABLE')
      },
      onOfflineReady() {
        console.log('App is ready for offline use')
      }
    })
    window.updateSW = updateSW
  })
}

// Initialize React app
const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    <AuthProvider>
      <App />
    </AuthProvider>
  )

  // Hide loading when app is mounted
  const loading = document.getElementById('loading')
  if (loading) {
    loading.style.display = 'none'
  }
  rootElement.style.display = 'block'
}
