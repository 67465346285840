import { useEffect } from 'react';
import withRouter from '../../../utils/withRouter';
import useTheme from '../../../hooks/useTheme';
import { SimpleSelect } from '../../../components/generic/SimpleSelect';
import { getFromLS, saveToLS } from '../../../utils/localStore/localStore';
import useToast from '../../../hooks/useToast';

//Theme Selectors
import { colorMap, styleMap, modeMap } from '../../../styles/themeMaps';
import { FlexBox } from '../../../components/generic/Layout';
import colorPairs from '../../../styles/colorPairs';

import styled from 'styled-components';
import NumericInput from '../../../components/numeric/NumericInput';
import { TextLine } from '../../../components/generic/Layout';

import * as RiIcons from 'react-icons/ri'
import ListView from '../../../components/listView/ListView';
import useTranslation from '../../../hooks/useTranslation';

import MobileNavBar from '../../../components/mobileNav/MobileNavBar';
import Loader from '../../../components/generic/Loader';
import IconButton from '../../../components/generic/IconButton';
import { Button } from '../../../components/generic/Core';
import ClientVersion from '../../../Version';
import Reload from '../../../components/header/Reload';
import ColorSelector from '../../../components/special/ColorSelector';
import { FormGroup } from '../../../components/form/FormComponents';
import AppTheme from './AppTheme';

const homeIcon = <RiIcons.RiHome2Fill />
const profileIcon = <RiIcons.RiUserSettingsFill />
const contactIcon = <RiIcons.RiContactsFill />

const profileContent = <ListView type='contactList' options={{ viewFilters: false }}></ListView>

const Wrapper = styled.div`
    width: 300px
    `

const Block = styled.div`
        width: 5rem;
        height: 2rem;
        background-color: ${({ theme }) => theme.secondary.main};
        border-radius: 1rem;
    `

    const SettingPage = styled.div` 
    max-width: 1000px;
    `

const config = [
    {
        key: 'home',
        name: 'Home',
        content: 'Tab content for Home',
        icon: homeIcon,
        disabled: false,
    },
    {
        key: 'profile', name: 'Profile',
        content: profileContent,
        icon: profileIcon,
        disabled: false,
    },
    {
        key: 'contact',
        name: 'Contact',
        content: 'Tab content for Contact',
        icon: contactIcon,
        disabled: true,
    },
];

const ThemeWrapper = styled.div`
    max-width: 250px;
    min-height: 260px;
`


const Board = () => {
    const { t, loadTrans } = useTranslation()
    const { ThemeContext } = useTheme()

    const { showToast, ToastComponent } = useToast();

    useEffect(() => {
        loadTrans()
    }, [])


    const saveTheme = (key, value) => {
        saveToLS('Theme', key, value)

    }

    const handleModeChange = (e) => {
        const mode = e.target.value
        saveTheme('themeMode', mode)
        ThemeContext.currentMode = mode
    }

    const handleColorChange = (e) => {
        const color = e.target.value
        let secondaryColor = colorPairs[`${color}`] || 'blue'
        saveTheme('themeColor', color)
        saveTheme('themeSecondaryColor', secondaryColor)
        ThemeContext.currentColor = color
        ThemeContext.secondaryColor = secondaryColor
    }

    const handleSecondaryColorChange = (e) => {
        const secondaryColor = e.target.value
        saveTheme('themeSecondaryColor', secondaryColor)
        ThemeContext.secondaryColor = secondaryColor
    }

    const handleStyleChange = (e) => {
        const style = e.target.value
        saveTheme('themeStyle', style)
        ThemeContext.currentStyle = style
    }

    const handleNavModeChange = (e) => {
        const navMode = e.target.value
        saveTheme('themeNavMode', navMode)
        ThemeContext.currentNavMode = navMode
    }

    return (
        <SettingPage>
            <h1>{t('application.settings.title')}</h1>
            <FormGroup.Row >
                <FormGroup.Col style={{ flex: 2 }}>
                    <ThemeWrapper>
                        <FlexBox $flex='1'>
                            <FlexBox $gap='1rem' $wrap='wrap' style={{minWidth: '170px'}}>
                                <TextLine $mt='0.5rem' $mr='2rem' $wrap='nowrap' >{t('theme.app.mode')}</TextLine>
                                <TextLine $mt='0.5rem' $mr='2rem' >{t('theme.app.navMode')}</TextLine>
                                <TextLine $mt='0.5rem' $mr='2rem' >{t('theme.app.style')}</TextLine>
                                <TextLine $mt='0.5rem' $mr='2rem' >{t('theme.app.color')}</TextLine>
                                <TextLine $mt='0.5rem' $mr='2rem' >{t('theme.app.secondary.color')}</TextLine>
                            </FlexBox>
                            <FlexBox $gap='1rem' $wrap='wrap'>
                                <SimpleSelect
                                    currentValue={ThemeContext.currentMode}
                                    handleChange={handleModeChange}
                                    options={modeMap} width='125px'
                                />
                                <SimpleSelect
                                    currentValue={ThemeContext.currentNavMode}
                                    handleChange={handleNavModeChange}
                                    options={modeMap} width='125px'
                                />
                                <SimpleSelect
                                    currentValue={ThemeContext.currentStyle}
                                    handleChange={handleStyleChange}
                                    options={styleMap} width='125px'
                                />
                                <ColorSelector target='color' onColorSelect={handleColorChange} />
                                <ColorSelector target='secondaryColor' onColorSelect={handleSecondaryColorChange} />
                            </FlexBox>
                        </FlexBox>
                    </ThemeWrapper>
                </FormGroup.Col>
                <FormGroup.Col style={{ flex: 3 }}>
                    <AppTheme />
                </FormGroup.Col>
            </FormGroup.Row>
            <br />
            <br></br>
            <br></br>
            <div style={{ background: '#aacc11', paddingBlock: '0.25rem' }}>
                <Reload />

            </div>
            <br></br>
            <br></br>
            <p style={{ fontSize: '16px' }} >App Version 13.11.2024 {ClientVersion}</p>
            <br></br>
            <br></br>
            <MobileNavBar />
        </SettingPage>
    )
}

const AppBoard = withRouter(Board)
export default AppBoard