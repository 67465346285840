import { useState } from 'react'
import styled from 'styled-components'

//Importing Dynamic Form Components
import Page from '../components/pageView/Page'

//Getting Hooks
import { useNavigate, Link } from 'react-router-dom'
import useApi from '../hooks/useApi'
import useAuth from '../hooks/useAuth'
import useTranslation from '../hooks/useTranslation'
import useOrg from '../hooks/useOrg'
import useProfile from '../hooks/useProfile'
import pause from '../utils/pause'


const FlexWrapper = styled.section`
  display: flex;
  justify-content: center
`
const Container = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => { theme.card.background }};
`
const Title = styled.h1`
  font-size: var(--font-XL);
  font-weight: bold;
`

const SubTitle = styled.h2`
  font-size: var(--font-M);
  font-weight: lighter;
`

function CreateOrg() {

  const [step, setStep] = useState(1)
  const { getMe } = useApi()
  const { Auth } = useAuth()
  const { switchOrg } = useOrg()
  const { initializeProfile } = useProfile()
  const userEmail = Auth.email

  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleSaveOrg = async () => {
    await pause(1500);
    // Retrieve the updated user
    const userData = await getMe(`user/email/${Auth.email}`)
    const user = userData.data
    // Switch to the new Org
    console.log('user ', user)
    console.log('ACTIVE ID', user.activeOrgId)
    await switchOrg(user.activeOrgId)
    await initializeProfile()

    navigate('/home')
  }

  const handleSaveUser = async () => {
    //await pause(1500);
    setStep(2)
  }


  const dispatch = (action, payload) => {
    if (action === 'back') { setStep(1); return null }
    if (action === 'saveNContinue' & step === 1) { handleSaveUser() }
    if (action === 'saveNContinue' & step === 2) { handleSaveOrg() }

  }

  return (
    <FlexWrapper>
      <Container>
        {/* NAME FORM  */}
        {(step === 1) ? (
          <>
            <Title data-testid='createOrg.user.title'>{t('createOrg.form.user.title')}</Title>
            <SubTitle data-testid='createOrg.user.subtitle'>{t('createOrg.form.user.subtitle')}</SubTitle>
            <br></br>
            <Page type='createOrgCardUser' dispatch={dispatch} options={{ mode: 'edit', load:true,forcedId: userEmail, forcedPath: 'user/email' }} />
          </>

        ) : <></>}
        {/* ORG FORM  */}
        {(step === 2) ? (
          <>
            <Title data-testid='createOrg.org.title'>{t('createOrg.form.org.title')}</Title>
            <SubTitle data-testid='createOrg.org.subtitle'>{t('createOrg.form.org.subtitle')}</SubTitle>
            <br></br>
            <Page type='createOrgCardOrganization' dispatch={dispatch} options={{ mode: 'new' }} />
          </>
        ) : <></>}
        {(step === 3) ? (
          <>
            <Title>{'Congratulations'}</Title>
            <SubTitle>{'Your Account is ready!'}</SubTitle>
            <br></br>
            <Link to='/home'>Access Your Account</Link>
          </>
        ) : <></>}
      </Container>
    </FlexWrapper>
  )

}

export default CreateOrg